import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

export interface Notification {
  message: string;
  type: 'neutral' | 'success' | 'error' | 'info';
  icon?: string;
  closeOnNavigate?: boolean;
}

export interface ExtraOptions {
  closeOnNavigate?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  notifications: Notification[] = [];

  private routerSubscription!: Subscription;

  constructor(private router: Router) {
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.notifications = this.notifications.filter(
          (notification) => !notification.closeOnNavigate
        );
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  notify(message: string, options?: ExtraOptions) {
    this.notifications.push({
      message: message,
      type: 'neutral',
      closeOnNavigate: options?.closeOnNavigate || false,
    });
    this.selfDestruct(message);
  }

  notifyInfo(message: string, options?: ExtraOptions) {
    // persist info notification
    this.notifications.push({
      message: message,
      type: 'info',
      closeOnNavigate: options?.closeOnNavigate || false,
    });
  }

  // custom notification
  notifyConnectionWarning(message: string, options?: ExtraOptions) {
    this.notifications.push({
      message: message,
      type: 'neutral',
      icon: 'connection-issue',
      closeOnNavigate: options?.closeOnNavigate || false,
    });
    this.selfDestruct(message, 10000);
  }

  notifySuccess(message: string, options?: ExtraOptions) {
    this.notifications.push({
      message: message,
      type: 'success',
      closeOnNavigate: options?.closeOnNavigate || false,
    });
    this.selfDestruct(message);
  }

  notifyError(message: string, options?: ExtraOptions) {
    this.notifications.push({
      message: message,
      type: 'error',
      closeOnNavigate: options?.closeOnNavigate || false,
    });
    this.selfDestruct(message);
  }

  selfDestruct(message: string, displayTime = 4500) {
    setTimeout(() => {
      const toast = this.notifications.find((it) => it.message === message);
      if (toast) this.removeNotification(toast);
    }, displayTime);
  }

  removeNotification(entity: Notification) {
    this.notifications = this.notifications.filter(
      (notification) => notification !== entity
    );
  }
}
