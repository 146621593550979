import { Injectable, NgZone, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  interval,
  Subject,
  Subscription,
  throttleTime,
} from 'rxjs';
import * as fromRoot from '@app/store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {
  private inactivityTimeout = 10 * 60 * 1000; // 10 minutes
  private warningTimeout = 5 * 60 * 1000; // 5 minutes

  private warningCounter$ = new BehaviorSubject<number | null>(null);
  private lastActivityTime: number = Date.now();
  private activitySubscription!: Subscription;
  private lastActivitySubject = new Subject<void>();

  private readonly channel: BroadcastChannel;
  private tabId = Date.now().toString();

  constructor(
    private rootStore: Store<fromRoot.rootState>,
    private ngZone: NgZone
  ) {
    this.channel = new BroadcastChannel('session-inactivity');
    this.setupChannelListener();
    this.setupBeforeUnloadListener();

    this.lastActivitySubject
      .pipe(throttleTime(5000))
      .subscribe(() => this.setLastActivityTime());
  }

  ngOnDestroy() {
    this.removeTabFromPausedSet();
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
    }
  }

  private broadcastMessage(action: 'start' | 'stop', tabId?: string): void {
    this.channel.postMessage({ action, tabId: tabId || this.tabId });
  }

  get warningCounter() {
    return this.warningCounter$.asObservable();
  }

  private getPausedTabs(): Set<string> {
    const storedTabs = localStorage.getItem('pausedTabs');
    return storedTabs ? new Set(JSON.parse(storedTabs)) : new Set();
  }

  private setPausedTabs(tabs: Set<string>) {
    localStorage.setItem('pausedTabs', JSON.stringify([...tabs]));
  }

  private addTabToPausedSet() {
    const pausedTabs = this.getPausedTabs();
    pausedTabs.add(this.tabId);
    this.setPausedTabs(pausedTabs);
  }

  private removeTabFromPausedSet() {
    const pausedTabs = this.getPausedTabs();
    pausedTabs.delete(this.tabId);
    this.setPausedTabs(pausedTabs);
  }

  startSessionInactivityCheck() {
    if (this.getPausedTabs().size === 0) {
      this.resetTimer();
      this.initializeActivityListener();
      this.startInactivityCheck();
    }
  }

  stopSessionInactivityCheck() {
    this.unsubscribeActivityListener();

    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
    }
  }

  stopSessionCheckForAllTabs() {
    this.addTabToPausedSet();
    this.stopSessionInactivityCheck();
    this.broadcastMessage('stop');
  }

  startSessionCheckForAllTabs() {
    this.removeTabFromPausedSet();
    this.startSessionInactivityCheck();
    this.broadcastMessage('start');
  }

  validateLastActivityAtAutoLogin(isAuthenticated: boolean): boolean {
    if (!isAuthenticated) {
      return false;
    }

    const lastActivityTimeString = localStorage.getItem('lastActivityTime');

    if (!lastActivityTimeString) {
      this.logout(); // for security reasons if lastActivityTime is not found, consider invalid session
      return false;
    }

    const lastActivityTime = parseInt(lastActivityTimeString, 10);
    const timeSinceLastActivity = Date.now() - lastActivityTime;

    if (timeSinceLastActivity >= this.inactivityTimeout) {
      this.logout();
      return false;
    }

    return true;
  }

  private initializeActivityListener() {
    window.addEventListener('click', this.resetTimer);
    window.addEventListener('keydown', this.resetTimer);
  }

  private unsubscribeActivityListener() {
    window.removeEventListener('click', this.resetTimer);
    window.removeEventListener('keydown', this.resetTimer);
  }

  private startInactivityCheck() {
    if (this.activitySubscription) {
      this.activitySubscription.unsubscribe();
    }

    this.activitySubscription = interval(1000).subscribe(() => {
      const now = Date.now();
      const timeSinceLastActivity = now - this.lastActivityTime;

      if (
        timeSinceLastActivity >= this.warningTimeout &&
        timeSinceLastActivity < this.inactivityTimeout
      ) {
        const remainingTime = Math.ceil(
          (this.inactivityTimeout - timeSinceLastActivity) / 1000
        );
        this.warningCounter$.next(remainingTime);
      }

      if (timeSinceLastActivity >= this.inactivityTimeout) {
        this.logout();
      }
    });
  }

  private setLastActivityTime = () => {
    localStorage.setItem('lastActivityTime', String(this.lastActivityTime));
  };

  private resetTimer = () => {
    this.lastActivityTime = Date.now();
    this.lastActivitySubject.next();
    this.warningCounter$.next(null);
  };

  private logout() {
    this.warningCounter$.next(null);
    this.rootStore.dispatch(fromRoot.logout());
  }

  private setupChannelListener(): void {
    this.channel.onmessage = (event) => {
      this.ngZone.run(() => {
        const { action } = event.data;

        if (action === 'start') {
          console.log('Received start message from another tab.');
          this.startSessionInactivityCheck();
        } else if (action === 'stop') {
          console.log('Received stop message from another tab.');
          this.stopSessionInactivityCheck();
        }
      });
    };
  }

  private setupBeforeUnloadListener(): void {
    window.addEventListener('beforeunload', () => {
      this.removeTabFromPausedSet();
      this.broadcastMessage('start');
    });
  }
}
