/**
 * Modules
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgSelectModule } from '@ng-select/ng-select';

/**
 * Components
 */
import { AppComponent } from './app.component';
import { NotificationComponent } from './modules/shared/components/notification/notification.component';
import { environment } from '@environments/environment';
import { NetworkInterceptor } from './modules/shared/interceptors/network.interceptor';

/**
 * State Management
 */
import {
  StoreRouterConnectingModule,
  RouterStateSerializer,
} from '@ngrx/router-store';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducers, effects } from './store';
import { CustomSerializer } from '@store/reducers/router.reducer';
/** not used in production */
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { storeFreeze } from 'ngrx-store-freeze';
import { clearState } from '@store/actions/clear.metareducer.actions';
import { DatePipe } from '@angular/common';
import { SessionLogoutWarningComponent } from './modules/shared/components/session-logout-warning/session-logout-warning.component';

export const metaReducers: MetaReducer<any>[] = !environment.production
  ? [clearState, storeFreeze]
  : [clearState];

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
    SessionLogoutWarningComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    StoreRouterConnectingModule.forRoot(),
    !environment.production
      ? StoreDevtoolsModule.instrument({ connectInZone: true })
      : [],
    NgSelectModule,
  ],
  providers: [
    DatePipe,
    { provide: RouterStateSerializer, useClass: CustomSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
